/** Author: Draco Chan < draco.chan@butterfly.com.au / im@dracochan.com >
 * Used to display accordion menu
 * Version: 2.0
 * Known issue: None
 * Requirement: jQuery 1.7+
 * HTML sample:
<ul id="menu">
	<li><a href="#"><span>level 1.1</span></a></li>
	<li class="active">
		<a href="#"><span>level 1.2</span></a>
		<ul>
			<li class="active"><a href="#"><span>level 2.1</span></a></li>
		</ul>
	</li>
</ul>
 * Usage:
jQuery(document).ready(function() {
	jQuery('ul#menu').accordionMenu();
});
 * Parameters:
expandClassname: classname to indicate the active item to be shown by default
onlyOneShown: close other expended menu if one sibling is shown.
enableParentLink: enable link of parent item
getArrowFunction: a function to get arrow. If this is provided, original link won't be overridden, arrow will be accordion trigger. 
 **/
(function ($) {

	'use strict';

	var DEFAULTS = {
		expandClassname: 'active',		// classname to indicate the active item to be shown by default
		onlyOneShown: true,				// close other expended menu if one sibling is shown.
		enableParentLink: false,		// enable link of parent item
		getArrowFunction: function(el){	// a function to get arrow. If this is provided, original link won't be overridden, arrow will be accordion trigger. 
			var arrow = jQuery('<span class="toggle"></span>');
			el.append(arrow);
			return arrow;
		}
	}

	jQuery.fn.accordionMenu = function(option){
		var options = jQuery.extend({}, DEFAULTS, typeof option == 'object' && option);

		return this.find('li > a, li > span').each(function() { 
			var jUl = jQuery(this).siblings('ul');
			if(jUl.length!=0){
				var trigger = jQuery(this);
				var hasArrow = false;
				if(options.getArrowFunction!==undefined) {
					hasArrow = true;
					trigger = options.getArrowFunction(jQuery(this));
				}

				if(options.expandClassname!==undefined && jQuery(this).parent('li').hasClass(options.expandClassname)) {
					trigger.addClass('open');
				} else {
					jUl.css('display', 'none'); /*hide it if not active*/
				}
				
				if(options.enableParentLink && jQuery(this).prop("tagName").toUpperCase()=="A") { /*set span as link instead*/
					var jSpan = jQuery(this).children('span');
					if(jSpan.length==0){
						jQuery(this).wrapInner('<span/>');
						jSpan = jQuery(this).children('span');
					}
					jSpan.hover( function(){
						jQuery(this).addClass('hover');
						jQuery(this).css('text-decoration', 'underline');
					}, function(){
						jQuery(this).removeClass('hover');
						jQuery(this).css('text-decoration', 'none');
					});
					jSpan.click(function(e){
						e.stopPropagation();
						window.location = jQuery(this).parent('a').attr('href');
					});
				}

				trigger.click(function(e){
					if(hasArrow && !jQuery(e.target).is(trigger))
						return true;

					e.stopPropagation();
					jUl.slideToggle(400);
					if(options.onlyOneShown){ //close others
						jQuery(this).parent('li').siblings('li').children('ul').each(function(index, element) {
						   jQuery(this).slideUp(400); 
						   jQuery(this).siblings('a').removeClass('open');
						});
					}
					if(jQuery(this).hasClass('open'))
						jQuery(this).removeClass('open');
					else
						jQuery(this).addClass('open');
					return false;
				});
			}
		});
	};

})(jQuery);